import { combineReducers } from 'redux';

import {connectRouter} from 'connected-react-router';
import {History} from "./history";
import {StatsReducer} from "./stats/statsReducer";

export const createRootReducer  = (history: typeof History) => combineReducers({
  router: connectRouter(history),
  stats: StatsReducer,
});

export const rootReducer = createRootReducer(History);


//each reducer has an interface it returns which encapsulates all state the reducer and associated components utilize.
//AppState is the combination of all reducer state interfaces.
//Reducer functions accept this as the first param, state, which is an instance of this type.
export type AppState = ReturnType<typeof rootReducer>;