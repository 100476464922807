import React from "react";
import type {FunctionComponent} from "react";
import { useStatsShareState} from "../../redux/stats/statsHooks";
import {sendShare} from "../../clients/firebase/functions";

export interface IShareDetailsProps
{

}

const ShareDetails: FunctionComponent < IShareDetailsProps > = ({}) => {
  const shareState = useStatsShareState();
  return (
    <div onClick={() => sendShare()}>Shares: {shareState}</div>
  );
}

export default ShareDetails;