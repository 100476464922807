
import {firebaseFunctions} from "./app";


export const sendLike = () => {
  const doSendLike = firebaseFunctions.httpsCallable('addLike');
  doSendLike({});
}
export const sendLove = () => {
  const doSendLove = firebaseFunctions.httpsCallable('addLove');
  doSendLove({});
}
export const sendShare = () => {
  const doSendShare = firebaseFunctions.httpsCallable('addShare');
  doSendShare({});
}
export const sendView = () => {
  const doSendView = firebaseFunctions.httpsCallable('addView');
  doSendView({});
}