import React from 'react';
import {store} from "./redux/store";
import {Provider} from "react-redux";
import LiveConnector from "./components/live/LiveConnector";
import HeaderMain from "./components/layout/header/HeaderMain";

function App() {
  return (
    <Provider store={store}>
      <LiveConnector>
        <HeaderMain />
        <div>
          Coming soon!!
        </div>
      </LiveConnector>
    </Provider>
  );
}

export default App;
