import React from "react";
import type {FunctionComponent} from "react";
import {useStatsLikeState} from "../../redux/stats/statsHooks";
import {sendLike,} from "../../clients/firebase/functions";

export interface ILikeDetailsProps {

}

const LikeDetails: FunctionComponent<ILikeDetailsProps> = ({}) => {
    const likeState = useStatsLikeState();
  return (
    <div onClick={() => sendLike()}>Like: {likeState}</div>
  );
}

export default LikeDetails;