import React from "react";
import type {FunctionComponent} from "react";
import {useLiveStatsData} from "../../services/firebase/hooks/live-stats-data";

export interface ILiveConnectorProps {

}

const LiveConnector: FunctionComponent<ILiveConnectorProps> = ({children}) => {
    useLiveStatsData();
  return (<>{children}</>);
}

export default LiveConnector;