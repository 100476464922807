import React from "react";
import type {FunctionComponent} from "react";
import { useStatsLoveState} from "../../redux/stats/statsHooks";
import {sendLove} from "../../clients/firebase/functions";

export interface ILoveDetailsProps {

}

const LoveDetails: FunctionComponent<ILoveDetailsProps> = ({}) => {
    const loveState = useStatsLoveState();
    return (
      <div onClick={() => sendLove()}>Love: {loveState}</div>
    );
}

export default LoveDetails;