import {IStatsClearAction, IStatsUpdateAction} from "./statsTypes";

export const statsClearAction = (): IStatsClearAction => {
  return {
     type: "@stats/clear",
  };
}

export interface IStatsUpdateActionParam {
  view?: number;
  like?: number;
  love?: number;
  share?: number;
}

export const statsUpdateAction = ({view, like, love, share}: IStatsUpdateActionParam): IStatsUpdateAction => {
  return {
    type: "@stats/update",
    like,
    love,
    share,
    view
  };
}