// For Firebase JS SDK v7.20.0 and later, measurementId is optional

export interface IFirebaseConfig {
  apiKey: string,
  authDomain: string,
  databaseURL: string,
  projectId: string,
  storageBucket: string,
  messagingSenderId: string,
  appId: string,
  measurementId: string
}

export const firebaseConfig: IFirebaseConfig = {
  apiKey: "AIzaSyAuw3ha-cx5CS3p2Mt2-TbAejCG1vy9F1g",
  authDomain: "jaxtin-me.firebaseapp.com",
  databaseURL: "https://jaxtin-me-default-rtdb.firebaseio.com",
  projectId: "jaxtin-me",
  storageBucket: "jaxtin-me.appspot.com",
  messagingSenderId: "570456424932",
  appId: "1:570456424932:web:bf0e9a76455964df1407f1",
  measurementId: "G-8J95REY6CS"
};
