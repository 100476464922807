import React from "react";
import type {FunctionComponent} from "react";
import { useStatsViewState} from "../../redux/stats/statsHooks";
import {sendView} from "../../clients/firebase/functions";

export interface IViewDetailsProps {

}

const ViewDetails: FunctionComponent<IViewDetailsProps> = ({}) => {
    const viewState = useStatsViewState();
    return (
      <div onClick={() => sendView()}>Views: {viewState}</div>
    );
}

export default ViewDetails;