import React from "react";
import type {FunctionComponent} from "react";
import s from "./HeaderMain.module.scss";
import ViewDetails from "../../stats/ViewDetails";
import LikeDetails from "../../stats/LikeDetails";
import ShareDetails from "../../stats/ShareDetails";
import LoveDetails from "../../stats/LoveDetails";

export interface IHeaderMainProps {

}

const HeaderMain: FunctionComponent<IHeaderMainProps> = ({}) => {
  return (
    <div className={s.wrapper}>
        <div className={s.name}>Jaxtin</div>
        <div className={s.stats}>
          <ViewDetails />
          <LikeDetails />
          <LoveDetails />
          <ShareDetails />
        </div>
    </div>

  );
}

export default HeaderMain;