import firebase from "firebase/app";
import {firebaseDatabase} from "./app";


export const databaseRef = (refPath: string) => firebaseDatabase.ref(refPath);

export const readDatabaseRef = <DatabaseValue = unknown>(refPath: string) => databaseRef(refPath)
  .once('value', (snapshot) => snapshot.val() as DatabaseValue);

export const pushDatabaseRef = <DatabaseValue = unknown>(refPath: string, value?: DatabaseValue) => databaseRef(refPath)
  .push(value ?? firebase.database.ServerValue.TIMESTAMP);

export const setDatabaseRef = <DatabaseValue = unknown>(refPath: string, data: DatabaseValue) => databaseRef(refPath)
  .set(data);

export const updateDatabaseRef = (refPath: string, data: Record<string, unknown>) => databaseRef(refPath)
  .update({
    ...data,
  });

export const subscribeDatabaseRef = <DatabaseValue = unknown>(refPath: string, callback: (value: DatabaseValue) => void): firebase.database.Reference => {
  const ref = databaseRef(refPath);
  ref.on('value', (snapshot: firebase.database.DataSnapshot) => callback(snapshot.val() as DatabaseValue));
  return ref;
}
