import {useEffect} from "react";
import {subscribeDatabaseRef} from "../../../clients/firebase/realtime-database";
import {useDispatch} from "react-redux";
import {IDatabaseStats} from "../../../clients/firebase/realtime-database-types";
import {statsClearAction, statsUpdateAction} from "../../../redux/stats/statsActions";
import type {Dispatch} from "../../../redux/stats/statsTypes";


export function useLiveStatsData() {
  const dispatch = useDispatch<Dispatch>();
  useEffect(() => {
    subscribeDatabaseRef<IDatabaseStats>(`/stats`, (liveStats)=> dispatch(statsUpdateAction(liveStats)));

    return () => {
      dispatch(statsClearAction());
    };
  }, []);
}
