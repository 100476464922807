import {AppState} from "../rootReducer";
import {
  getStatsLikeState,
  getStatsLoveState,
  getStatsShareState,
  getStatsState,
  getStatsViewState
} from "./statsSelectors";
import {useSelector} from "react-redux";


export const useStatsViewState = () => useSelector(getStatsViewState);
export const useStatsLikeState = () => useSelector(getStatsLikeState);
export const useStatsLoveState = () => useSelector(getStatsLoveState);
export const useStatsShareState = () => useSelector(getStatsShareState);