import {applyMiddleware, compose, createStore} from 'redux';
import thunk from "redux-thunk"
import {History} from "./history";
import {routerMiddleware} from 'connected-react-router'
import {composeWithDevTools} from "redux-devtools-extension";
import {rootReducer} from "./rootReducer";


const composeEnhancers = composeWithDevTools({
  name: 'Jaxtin-me',
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});


export default function configureStore(preloadedState?: {}) {
  const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  return  createStore(
    rootReducer,
    preloadedState || {},
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(History), // for dispatching history actions
        thunk, // thunk
      ),
    ),
  );
}

export const store = configureStore();