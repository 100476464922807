import {IStatsState, StatsActionTypes} from "./statsTypes";

const initialStatsState: IStatsState = {
  like: 0,
  love: 0,
  share: 0,
  view: 0,
}

export function StatsReducer(state: IStatsState = initialStatsState, action: StatsActionTypes): IStatsState
{
  switch (action?.type){
    case "@stats/clear": {
      return {
        ...initialStatsState,
      };
    }
    case "@stats/update": {
      const { like, love, share, view } = action;
      return {
        like: like ?? 0,
        love: love ?? 0,
        share: share ?? 0,
        view: view ?? 0,
      };
    }
    default: {
      return state;
    }
  }
}